.primary-card {
  border: 0;
  border-radius: 0;
  background: linear-gradient(207.19deg, #FFCEC2 -43.81%, #83B9E6 99.18%);

  .card-img-overlay {
    color: #FFFFFF;

    &.no-bg {
      background: none;
    }
  }

  .card-title {
    text-align: left;
    margin-bottom: 20px;
  }

  .card-img-overlay {
    display: flex;
    flex-flow: row;
    padding: 20px;
  }

  .small-text {
    font-size: 8px;
    line-height: 1.1em;
  }

  .btn-rounded {
    margin-left: auto;
  }
}

.primary-card-slider {

  .has-video:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url("../img/play-button-white.svg");
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: center;
    cursor: pointer;
  }

  .playing:after {
    background-image: none;
  }

  @include responsive(max-sm) {
    overflow: inherit !important;
  }

  &.dark-blue-theme {
    .swiper-pagination-bullet-active {
      background-color: $color-light-blue
    }
    .swiper-pagination-bullet {
      border-color: $color-light-blue
    }
  }

  .swiper-pagination {
    position: static;
    margin-top: 20px;
  }

  .ratio {
    iframe {
      z-index: -1;
    }
  }
}