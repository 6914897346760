$footer-bg: #00A3E0;

footer {
  position: relative;
  background-color: $footer-bg;
  color: #FFFFFF;
  padding: 40px 0;
  z-index: 0;
  overflow: hidden;

  @include responsive(min-lg) {
    padding: 80px 0;
  }

  .reference-list {
    font-size: 16px;
    line-height: 1.1em;

    @include responsive(min-md) {
      font-size: 16px;
      line-height: 24px;
    }

    + ul {
      margin-top: 30px;
    }

    li {
      display: flex;

      + li {
        margin-top: 1em;
      }
    }

    b {
      min-width: 28px;
      font-feature-settings: "tnum";
      padding-right: 4px;
    }

    p {
      font-weight: 300;
    }

    + .footer-bottom {
      margin-top: 40px;
    }

    sup {
      top: -1.5em;
      font-size: .40em;
    }
  }

  .footer-bottom {
    padding-top: 40px;
    border-top: 1px solid #FFFFFF;
  }

  .footer-links {
    @include responsive(max-sm) {
      margin-top: 40px;
    }
  }

  .link-list {
    display: flex;
    flex-flow: column;

    .link-item + .link-item {
      margin-top: 25px;

      @include responsive(min-md) {
        margin-top: 0;
      }
    }

    .link-item {
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
      color: #FFFFFF;
      text-decoration: none;
      transition: ease color .3s;

      @include responsive(min-md) {
        margin-bottom: 20px;
        font-size: 18px;
      }

      &:not(b):hover {
        color: $color-dark-blue;
      }

      &.disabled {
        color: #6c757d;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  .code-info {
    display: block;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    margin-top: 40px;

    @include responsive(min-md) {
      margin-top: 20px;
    }
  }

  .brand-list {
    margin-bottom: 20px;
  }

  a {
    transition: ease all .3s;
  }
}