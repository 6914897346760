$header-bg: #00A3E0;
$hamburger-menu-line-bg: #ffffff;
$link-color: #ffffff;

header {
  background-color: $header-bg;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  padding: 15px 0;
  z-index: 12;

  @include responsive(min-lg) {
    padding: 24px;
  }

  .navbar {
    padding: 0;

    @include responsive(min-md) {
      justify-content: center;
    }
  }

  .navbar-nav {
    @include responsive(min-md) {
      padding: 0 80px;
    }
  }

  .nav-item:not(:last-child) {
    @include responsive(min-md) {
      margin-right: 40px;
    }
  }

  .navbar-brand {
    padding: 0;
    margin: 0 auto;

    @include responsive(min-md) {
      margin: 0;

      img {
        width: 112px;
        height: 52px;
      }
    }
  }

  .navbar-toggler {
    position: relative;
    height: 15px;
    width: 15px;
    cursor: pointer;
    padding: 0;
    border: 0;
    transform: translateY(-5px);

    &:focus {
      box-shadow: none;
    }

    .line {
      height: 15px;
      width: 16px;
      position: absolute;
      left: 0;
      top: 1.9px;
      transition: transform 300ms ease-in-out;
    }

    .line-inner {
      height: 1px;
      width: 17px;
      background: $hamburger-menu-line-bg;
      border-radius: 4px;
      position: absolute;
      transition: transform 200ms ease-in-out;
      transition-delay: 200ms;
      right: 0;
      left: 0;
    }

    .line-inner-1 {
      top: 6px;
      transform: translateY(-2px);
    }

    .line-inner-2 {
      bottom: 7px;
      transform: translateY(5px);
    }

    &[aria-expanded="true"] .line-1 {
      transform: rotateZ(45deg);
    }

    &[aria-expanded="true"] .line-2 {
      transform: rotateZ(-45deg);
    }

    &[aria-expanded="true"] .line-inner {
      transform: none;
      transition: transform 200ms ease-in-out;
    }

    &[aria-expanded="true"] .line {
      transition-delay: 200ms;
    }
  }

  .navbar-expand-md .navbar-nav .nav-item {

    .nav-link {
      padding: 0;
    }
  }

  .navbar-expand-md .navbar-collapse {
    @include responsive(min-md) {
      display: inline !important;
      flex: 0 0 auto;
    }
  }

  .nav-link {
    padding: 0;
    font-weight: 300;
    font-size: 28px;
    line-height: 48px;
    color: $link-color;

    @include responsive(min-md) {
      font-size: 21px;
      line-height: 25px;
    }

    &:hover,
    &.active {
      color: $color-dark-blue;
    }
  }

  .navbar-collapse {

    @include responsive(max-sm) {
      position: fixed;
      top: 70px;
      height: calc(100vh - 70px) !important;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      background: $header-bg;
      box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.15);
      padding: 20px;
      overflow: auto;
      visibility: hidden;
      opacity: 0;
      transition: ease-in-out all .4s;
    }
  }

  .brand-list-description,
  .brand-list {
    @include responsive(min-md) {
      display: none;
    }
  }

  .external-link {
    @include responsive(min-md) {
      order: 3;

      img {
        width: 34px;
        height: 40px;
      }
    }
  }
}
