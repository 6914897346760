.btn-rounded {
  border-radius: 54px;
  border: 0;
  background: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.1em;
  padding: 10px 18px 12px 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}