.product-information-scheme {
  position: relative;
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  transform: translateX(-7%);

  .scheme-icon {
    position: absolute;
    width: 55px;
    height: 55px;
    background-position: center;
    background-size: 34px;
    background-repeat: no-repeat;
    background-color: rgba(#ffffff, 0.96);
    border-radius: 50%;
    cursor: pointer;
    transition: ease all .3s;

    @include responsive(min-md) {
      background-size: 64px;
      width: 100px;
      height: 100px;
    }

    &.active {
      width: 60px;
      height: 60px;
      cursor: default;
      background-color: rgba($color-dark-blue, 0.8);

      @include responsive(min-md) {
        width: 150px;
        height: 150px;
        background-size: 94px;
      }
    }

    &:nth-child(1) {
      top: 16%;
      right: -1%;
    }

    &:nth-child(2) {
      top: 3%;
      left: 51%;
    }

    &:nth-child(3) {
      top: 14%;
      left: 16%;
    }

    &:nth-child(4) {
      top: 60%;
      left: 26%;
    }

    &:nth-child(5) {
      top: 42%;
      left: 71%;
    }
  }
}

.scheme-texts {
  .text-item {
    display: none;
    min-height: 115px;

    &.active {
      display: block;
    }
  }
}