.primary-form {

  .form-floating,
  .form-group {
    margin-bottom: 28px;

    @include responsive(min-md) {
      margin-bottom: 35px;
    }
  }

  .form-floating {
    > label {
      font-size: 20px;
      font-weight: 300;
      left: 34px;
      padding: 0 10px;
      top: 17px;
      bottom: 1px;
      height: auto;
      border: 0;
      transition: ease all .2s;
    }
  }

  textarea.form-control {
    min-height: 230px;
    resize: none;
  }

  .form-control {
    padding: 18px 44px;
    border: 1px solid $color-dark-blue;
    border-radius: 35px;
    box-shadow: none;
    font-size: 16px;

    &:not(:placeholder-shown)~label,
    &:focus~label {
      top: -6px;
      opacity: 1;
    }

    & ~ label {
      top: 16px;
      padding: 0 10px;
      bottom: inherit;
      background-color: #FFFFFF;
    }
  }

  .form-check {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
}
#formsubmitModal .modal-dialog {
  max-width: 500px;
}

#footnotepopup .modal-dialog .modal-content, #formsubmitModal .modal-dialog .modal-content {
  padding: 30px 40px;
  border-radius: 30px;
  border: 2px solid #ff6a39;
}

#footnotepopup .modal-dialog .modal-content .modal-header, #formsubmitModal .modal-dialog .modal-content .modal-header {
  padding: 0;
  border: 0;
}

#formsubmitModal .modal-dialog .modal-content .modal-header {
  border-bottom: 1px solid #dee2e6;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

#formsubmitModal .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  opacity: 1;
  padding: 0;
  margin: 0;
  z-index: 11;
}

#formsubmitModal .modal-dialog .modal-content .modal-header .close {
  width: 45px;
  height: 45px;
  top: 5px;
  right: 5px;
}

#footnotepopup .modal-dialog .modal-content, #formsubmitModal .modal-dialog .modal-content {
  padding: 30px 40px;
  border-radius: 30px;
  border: 2px solid #ff6a39;
}

#formsubmitModal.error .modal-dialog .modal-content {
  border-color: red;
}

#formsubmitModal.success .modal-dialog .modal-content {
  border-color: green;
}

#formsubmitModal p {
  margin-bottom: 1.875rem;
  font-size: 1.125rem;
  line-height: 2.125rem;
  font-weight: 300;
}
