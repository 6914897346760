.tab-infographic {
  display: flex;
  flex-flow: column;
  align-items: center;

  @include responsive(min-lg) {
    flex-flow: row;
  }

  .tab-col {
    flex-grow: 1;

    &.left,
    &.right {
      max-width: 396px;
      width: 100%;

      @include responsive(max-md) {
        order: 1;

        &.active {
          display: flex;
          flex-flow: wrap;

          .btn {
            flex-basis: calc(33.3333333% - 4px);
            margin: 0 2px;
          }

          .collapse {
            flex-basis: 100%;
          }
        }

        &:not(.active) {
          display: none;
        }
      }
    }

    &.middle {
      display: flex;
      flex-flow: row;
      justify-content: center;
      margin-bottom: 30px;

      @include responsive(min-lg) {
        padding: 0 20px;
        margin-bottom: 0;
      }
    }
  }

  .btn {
    margin-bottom: 15px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    width: 100%;
    box-shadow: none;

    &:not([aria-expanded="true"]) {
      background-color: transparent;
    }

    &[aria-expanded="true"] {
      pointer-events: none;
    }
  }

  #blueTabs .btn-rounded[aria-expanded="true"] {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    color: $color-light-blue;
  }

  #orangeTabs .btn-rounded[aria-expanded="true"] {
    background-color: $color-orange;
    border-color: $color-orange;
  }

  .collapse-body {
    padding: 15px 3px 0;

    @include responsive(min-lg) {
      padding: 5px 0 15px;
    }
  }

  .circle-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border-width: 3px;
    flex-shrink: 0;
    font-family: "Saol Standard";
    font-size: 45px;
    transition: ease all .3s;

   @include responsive(min-xxl) {
     font-size: 50px;
     border-width: 5px;

     &:first-child {
       margin-right: -25px;
     }
   }

    &:last-child {
      margin-left: -25px;
    }

    &:not(.active) {
      @include responsive(max-md) {
        opacity: 0.35;
      }
    }

    &.blue {
      border-color: $color-light-blue;
      border-style: solid;
      color: $color-light-blue;
      z-index: 1;
    }

    &.orange {
      border-color: $color-orange;
      border-radius: 50%;
      border-style: dotted;
      box-shadow: 0 0 0 0 $color-orange;
      color: $color-orange;
    }

    @include responsive(min-xxl) {
      width: 370px;
      height: 370px;
    }

    &:nth-child(1) span {
      width: 63px;
      height: 34px;
      line-height: 43px;

      @include responsive(min-xxl) {
        width: 70px;
        height: 37px;
        line-height: 47px;
      }
    }

    &:nth-child(2) span {
      width: 113px;
      height: 35px;
      line-height: 44px;

      @include responsive(min-xxl) {
        width: 126px;
        height: 37px;
        line-height: 46px;
      }
    }

    span {
      line-height: 0;
      text-align: center;
      font-weight: bold;
    }
  }

  .tab-infographic-small-text {
    order: 9;
  }
}

.tab-infographic-small-text {
  font-size: 16px;
  line-height: 1.1em;
  margin-top: 20px;

  @include responsive(min-md) {
    margin-top: 40px;
    font-size: 16px;
  }
}