.general-section {
  padding: 60px 0;
  background-color: #FFFFFF;

  @include responsive(min-md) {
    padding: 80px 0;
  }

  @include responsive(min-lg) {
    padding: 100px 0;
  }

  &.gradient-type1 {
    background: linear-gradient(207.19deg, #FFCEC2 -43.81%, #83B9E6 99.18%);
  }

  &.bg-dark-blue {
    background-color: $color-dark-blue;
  }

  &.bg-light-blue {
    background-color: $color-light-blue;

    .tab-infographic .circle-tab.blue {
      border-color: #FFFFFF;
      border-style: solid;
      color: #FFFFFF;
      z-index: 1;
    }
  }

  &.has-wave-bg {
    position: relative;
    background: linear-gradient(213.08deg, #FDC7BC 9.33%, #83B9E6 57.1%);
    color: #000000;

    &:not(.reversed) {
      padding-top: 117px;

      @include responsive(min-sm) {
        padding-top: 265px;
      }
    }

    .wave {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: top center;

      @include responsive(min-md) {
        height: 155px;
        background-position: bottom center;
        transform: rotate(180deg);
      }
    }

    &.reversed {
      padding-bottom: 117px;

      @include responsive(min-sm) {
        padding-bottom: 265px;
      }

      .wave {
        top: inherit;
        bottom: -1px;
        transform: rotate(-180deg);
        background-position: top center;

        @include responsive(min-md) {
          transform: none;
          background-position: bottom center;
        }
      }
    }

    .tab-content {
      position: relative;
      z-index: 1;
    }
  }

  a:not(.list-group-item):not(.btn-rounded):not(.nav-link) {
    color: $color-dark-blue;
    background-image: linear-gradient(to top, transparent 0%, transparent 5%, $color-dark-blue 10%, $color-dark-blue 2px, transparent 2px);
    text-decoration: none;
  }

  p {
    font-weight: 300;
    margin-bottom: 1.8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  b {
    font-weight: 500;
  }

  .small-text {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
  }
}