@font-face {
    font-family: 'Beatrice';
    src: url('../fonts/web-fonts/subset-Beatrice-Light.woff2') format('woff2'),
        url('../fonts/web-fonts/subset-Beatrice-Light.woff') format('woff'),
        url('../fonts/web-fonts/subset-Beatrice-Light.ttf') format('truetype'),
        url('../fonts/web-fonts/subset-Beatrice-Light.svg#Beatrice-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Saol Standard';
    src: url('../fonts/web-fonts/subset-SaolStandard-LightItalic.woff2') format('woff2'),
        url('../fonts/web-fonts/subset-SaolStandard-LightItalic.woff') format('woff'),
        url('../fonts/web-fonts/subset-SaolStandard-LightItalic.ttf') format('truetype'),
        url('../fonts/web-fonts/subset-SaolStandard-LightItalic.svg#SaolStandard-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Saol Standard';
    src: url('../fonts/web-fonts/subset-SaolStandard-Light.woff2') format('woff2'),
        url('../fonts/web-fonts/subset-SaolStandard-Light.woff') format('woff'),
        url('../fonts/web-fonts/subset-SaolStandard-Light.ttf') format('truetype'),
        url('../fonts/web-fonts/subset-SaolStandard-Light.svg#SaolStandard-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Saol Text';
    src: url('../fonts/web-fonts/SaolText-Light.woff2') format('woff2'),
    url('../fonts/web-fonts/SaolText-Light.woff') format('woff'),
    url('../fonts/web-fonts/SaolText-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('../fonts/web-fonts/founders-grotesk-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('../fonts/web-fonts/founders-grotesk-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}
