.icon-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  .list-item {
    display: flex;
    align-items: flex-start;

    img {
      margin-right: 12px;
      margin-top: 2px;
      flex-shrink: 0;

      @include responsive(min-lg) {
        width: 52px;
        height: 52px;
        margin-right: 43px;
        margin-top: -15px;
      }
    }

    + .list-item {
      margin-top: 20px;

      @include responsive(min-lg) {
        margin-top: 50px;
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}