@import "app/assets/fonts/web-fonts/stylesheet";
@import "node_modules/swiper/swiper";
@import "node_modules/swiper/modules/navigation/navigation";
@import "node_modules/swiper/modules/pagination/pagination";

:root {
  --swiper-pagination-bullet-horizontal-gap: 12px;
  --swiper-theme-color: #071D49;
}

body {
  font-size: 21px;
  line-height: 1.1em;

  @include responsive(min-lg) {
    font-size: 28px;
  }

  @include responsive(max-md) {
    &.navigation-open {
      overflow: hidden;

      .navbar-collapse {
        opacity: 1;
        visibility: visible;
      }

      .nav-item,
      .brand-list-description,
      .brand-list {
        animation: translateY30 0.7s ease-out 0s 1 normal both;
      }

      .brand-list-description,
      .brand-list {
        animation-delay: 1s;
      }
    }
  }
}

h1, h2, h3, h4, h6 {
  font-family: 'Saol Standard';
  font-weight: 300;
}


h1 {
  font-size: 45px;
  line-height: 51px;
  margin-bottom: 40px;

  @include responsive(md) {
    margin-bottom: 80px;
  }

  @include responsive(min-xl) {
    font-size: 61px;
    line-height: 68px;
  }
}

h2 {
  font-size: 34px;
  line-height: 38px;
  margin-bottom: 1em;

  @include responsive(min-md) {
    font-size: 45px;
    line-height: 51px;
  }
}

h5 {
  font-family: 'Saol Text';
  font-weight: 300;
  font-size: 26px;
  line-height: 28px;

  @include responsive(min-lg) {
    font-size: 30px;
    line-height: 38px;
  }
}

.navigation-open {
  .nav-item:nth-child(1) {
    animation-delay: 0.5s;
  }

  .nav-item:nth-child(2) {
    animation-delay: 0.6s;
  }

  .nav-item:nth-child(3) {
    animation-delay: 0.7s;
  }

  .nav-item:nth-child(4) {
    animation-delay: 0.8s;
  }

  .nav-item:nth-child(5) {
    animation-delay: 0.9s;
  }

  .nav-item:nth-child(6) {
    animation-delay: 1s;
  }

  .nav-item:nth-child(7) {
    animation-delay: 1.2s;
  }

  .nav-item:nth-child(8) {
    animation-delay: 1.3s;
  }

  .nav-item:nth-child(9) {
    animation-delay: 1.4s;
  }
}

.color-light-blue {
  color: $color-light-blue;
}

.color-dark-blue {
  color: $color-dark-blue;
}

.color-orange {
  color: $color-orange;
}

.bg-dark-blue {
  background-color: $color-dark-blue;
}

.info-list {
  .list-item:not(:last-child) {
    @include responsive(max-md) {
      margin-bottom: 30px;
    }
  }

  img {
    @include responsive(max-md) {
      width: 116px;
      height: 116px;
    }
  }
}

.image-slider-wrapper {
  position: relative;

  .swiper-button-next {
    right: -30px;

    @include responsive(min-xl) {
      right: -50px;
    }

    @include responsive(min-xxl) {
      right: -100px;
    }

    @include responsive(min-exxl) {
      right: -140px;
    }
  }

  .swiper-button-prev {
    left: -30px;

    @include responsive(min-xl) {
      left: -50px;
    }

    @include responsive(min-xxl) {
      left: -100px;
    }

    @include responsive(min-exxl) {
      left: -140px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include responsive(min-lg) {
      display: none;
    }
  }
}

.primary-card-slider,
.image-slider-wrapper {
  .swiper-actions {
    position: static;
    margin-top: 35px;

    @include responsive(min-lg) {
      margin-top: 65px;
    }
  }
}

.image-slider {
  .slider-img {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .swiper-slide {
    display: flex;
    flex-flow: column;
    height: auto;
  }
}

.person-slider-wrapper {
  position: relative;
}

.swiper-actions {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;


  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid #FFFFFF;
    background-color: transparent;
    opacity: 1;

    @include responsive(min-lg) {
      width: 25px;
      height: 25px;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: #FFFFFF;
  }

  .swiper-button-next,
  .swiper-button-prev {
    margin-top: 0;

    &:after {
      display: none;
    }
  }

  .swiper-pagination,
  .swiper-button-next,
  .swiper-button-prev {
    position: static;
    line-height: 0;
    height: inherit;
  }

  .swiper-pagination {
    margin: 0 23px;
    width: auto;
    transform: none !important;
  }

  .swiper-button-disabled {
    visibility: hidden;
  }
}

.person-thumb-slider,
.person-slider {
  img {
    background: linear-gradient(0deg, #FAC5BB -7.58%, #FFD3CB 47.33%, #7BB6E2 100%);
  }
}

.person-thumb-slider {

  .swiper-wrapper {
    justify-content: center;
  }

  .swiper-slide {
    width: calc(33.3333333% - 8px);
    max-width: 116px;
    transition: ease border, padding .1s;

    &:last-child {
      margin-right: 0 !important;
    }
  }

  .swiper-slide-thumb-active {
    border: 1px solid #FFFFFF;
    padding: 4px;
  }
}

.person-slider {
  max-width: 370px;
  margin: 10px auto 30px;

  @include responsive(min-md) {
    max-width: 100%;
    margin-top: 0;
  }

  .person-name {
    position: absolute;
    transform: rotate(-90deg) translateX(calc(-100% - 15px)) translateY(30px);
    transform-origin: 0;
    font-family: 'Beatrice';
    font-weight: 300;
    font-size: 26px;
    line-height: 39px;
    z-index: 2;

    @include responsive(min-md) {
      position: static;
      transform: none;
      margin-bottom: 5px;
      font-size: 36px;
      line-height: 53px;
    }
  }
}

.circle-img-tabs {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;

  @include responsive(min-md) {
    margin: 0;
  }

  .nav-item + .nav-item {
    margin-left: 16px;
  }

  img {
    background: #FACDC7;
    border: 1px solid #FFFFFF;
    outline: 2px solid transparent;
    transition: ease outline, opacity .1s;
    cursor: pointer;
    opacity: 0.5;

    @include responsive(max-md) {
      width: 68px;
      height: 68px;
    }

    &.active {
      outline-color: #FFFFFF;
      border-width: 2px;
      outline-offset: 4px;
      opacity: 1;
    }
  }
}

[data-ease-class] {
  opacity: 0;
}

.animateStyleMedium {
  animation: translateY30 1.2s ease-out 0s 1 normal both;
}

.animateStyleSlow {
  animation: translateY30 1.4s ease-out 0s 1 normal both;
}

.animateStyleFast {
  animation: translateY30 .7s ease-out 0s 1 normal both;
}

@keyframes translateY30 {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.video-poster-img {
  z-index: 1;
  object-fit: cover;
  object-position: center;
}

.video-play-btn {
  z-index: 2;
  cursor: pointer;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;

  img {
    width: 50px;
    height: 50px;

    @include responsive(min-xxl) {
      width: 150px;
      height: 150px;
    }
  }
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.ratio-9x16 {
  --bs-aspect-ratio: 177.78%;
}

.sitemap-nav {

  .nav-item + .nav-item {
    margin-top: 10px;
  }

  .nav-link {
    padding: 0;
    font-weight: 500;
    color: $color-light-blue;

    &:hover {
      color: $color-dark-blue;
    }
  }
}

.brand-list-description {
  display: block;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px;

  @include responsive(min-md) {
    font-size: 18px;
  }

  &.has-border-on-mobile {
    font-size: 18px;

    @include responsive(max-sm) {
      margin-top: 40px;
      padding-top: 20px;
      border-top: 1px solid #FFFFFF;
    }
  }
}

.brand-list {
  max-width: 415px;
  display: flex;
  margin: 0 -2.5px;

  .brand-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 33.3333333%;
    height: 105px;
    background-color: #FFFFFF;
    margin: 0 2.5px;
    overflow: hidden;

    &:hover img {
      transform: scale(1.1);
    }

    a {
      width: 100%;
      padding: 0 15px;
    }

    img {
      transition: ease all .3s;
      width: 100%;
    }
  }
}

.fw-500 {
  font-weight: 500 !important;
}