.primary-banner {
  position: relative;
  display: flex;
  flex-flow: row;
  overflow: hidden;

  &.gradient-type1 {
    background: radial-gradient(100% 451.83% at 0% 100%, #FFCEC2 47.77%, #DBC2D9 71.38%, #73B5E3 99.95%);
  }

  &.gradient-type2 {
    background: radial-gradient(129.81% 583.4% at -29.81% 156.9%, #FFCEC2 4.69%, #DBC2D9 39.84%, #73B5E3 83.47%);
  }

  &.homepage-type {
    &:after {
      content: '';
      width: 100%;
      height: 60%;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(7, 29, 73, 0) 0%, #071D49 100%);
    }

    .banner-title,
    .banner-small-text {
      color: #FFFFFF;
    }

    .banner-content {
      right: 0;
      left: 0;

      @include responsive(min-md) {
        position: absolute;
        bottom: 60px;
        padding-right: 0;
      }
    }
  }

  .banner-img {
    max-width: 1140px;
    width: 100%;

    img {
      @include responsive(max-md) {
        width: 100%;
      }
    }
  }

  .banner-content {
    position: absolute;
    top: 60px;
    right: 20px;
    left: 20px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 0 auto;
    z-index: 1;

    @include responsive(min-md) {
      position: static;
      padding-right: 80px;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &.type2 {
      @include responsive(max-sm) {
        top: inherit;
        bottom: 15px;
        text-align: left;

        .banner-title {
          margin-bottom: 30px !important;
        }
      }
    }
  }

  .banner-title {
    flex-shrink: 0;
    color: #FFFFFF;

    @include responsive(min-md) {
      color: $color-dark-blue;
    }

    .colored {
     color: $color-light-blue;
    }
  }

  .banner-small-text {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.1em;
    color: #FFFFFF;

    @include responsive(min-md) {
      color: $color-dark-blue;
    }
  }

  video {
    width: 100%;

    @include responsive(min-md) {
      height: 650px;
      object-fit: cover;
      object-position: top;
    }
  }
}